<template>
<div>
  <health-check />
</div>
</template>

<script>
import HealthCheck from './health-check/HealthCheck.vue';

export default {
  name: 'DataCenter',
  components: {
    HealthCheck,
  },
};
</script>

<style scoped lang="scss">

</style>
