<template>
<div>
  <riseup-button v-if="!doneCheck" title="בדוק תקינות" :loading="loading" @click="startCheck" />
  <div v-else-if="loading">
    <tiny-loader />
  </div>
  <div v-else class="data-center-container">
    <div>
      <div v-if="feesInsight">
        <div>עמלות: {{ feesInsight }}</div>
      </div>
      <div v-else>
        <div>אין עמלות</div>
      </div>
    </div>
    <div>
      <div v-if="emergencyInsight">
        <div>עמלות: {{ emergencyInsight }}</div>
      </div>
      <div v-else>
        <div>אין עדכונים חדשים</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'HealthCheck',
  components: {
    TinyLoader: BaseUI.TinyLoader,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      doneCheck: false,
    };
  },
  computed: {
    ...mapState('dataCenter', ['currentBudgetDateInsights', 'loading']),
    feesInsight() {
      return this.currentBudgetDateInsights.find(({ topic }) => topic.includes('fees'));
    },
    emergencyInsight() {
      return this.currentBudgetDateInsights.find(({ topic }) => topic.includes('emergency'));
    },
  },
  methods: {
    ...mapActions('dataCenter', ['getPastInsights']),
    async startCheck() {
      await this.getPastInsights();
      this.doneCheck = true;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
