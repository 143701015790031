<template>
  <banner-page class="full-height"
               :background-position="bannerBackgroundPosition"
               :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
               :background-color="bannerBackgroundColor">
    <alert-page :img="require('@/assets/happy-star-drawing.png')"
                img-style="small"
                title="נראה שאנחנו כבר מכירים!"
                show-support-footer>
      <template #content>
        <div>
          <div>מספר הטלפון שלך כבר קיים אצלנו בשירות <span class="ri-large-bold-body">כחשבון נפרד</span>.</div>
          כדי להצטרף לתזרים של {{ inviterName }}, <span class="ri-large-bold-body">צריך למחוק את החשבון שלך</span> ולהצטרף למנוי משותף.
        </div>
        <riseup-button
          class="margin-top-l"
          title="למחיקה והצטרפות מחדש"
          :loading="loading"
          color="red"
          @click="deleteAndCreateMember"/>
        <separator text="או" class="margin-vertical-sm"/>
        <riseup-button
          title="להמשיך כרגיל בשירות"
          @click="redirectToHome"/>
      </template>
    </alert-page>
  </banner-page>
</template>

<script>
import AlertPage from '@/base-components/AlertPage';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import subscriptionStateApi from '@/api/SubscriptionStateApi';
import ChurnPopup from '../../../authenticated/churn/ChurnPopup';
import ChurnThroughMemberInviteCompletePopup from './ChurnThroughMemberInviteCompletePopup';

export default {
  name: 'MemberInviteExistingCustomer',
  components: {
    AlertPage,
    Separator: BaseUI.Separator,
    RiseupButton: BaseUI.V2Components.RiseupButton,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      inviterName: 'השותפ/ה',
      subscriptionState: {},
      loading: true,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
    };
  },
  async created() {
    if (this.$cookies.get('memberInviteDetails')) {
      const { inviterName, inviteToken } = this.$cookies.get('memberInviteDetails');
      this.inviterName = inviterName;
      this.inviteToken = inviteToken;
    }
    this.subscriptionState = await subscriptionStateApi.fetchSubscriptionState();
    this.loading = false;
    Segment.trackUserGot('MemberInviteExistingCustomerPage_Entered', { state: this.subscriptionState.state });
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    async deleteAndCreateMember() {
      Segment.trackUserGot('MemberInviteExistingCustomerPage_ChurnClicked');
      this.openModal({
        component: ChurnPopup,
        props: {
          memberInviteImmediateChurn: true,
        },
        eventHandlers: {
          success: this.showSuccessPopup,
        },
      });
    },
    async showSuccessPopup() {
      this.openModal({
        component: ChurnThroughMemberInviteCompletePopup,
        props: {
          inviteToken: this.inviteToken,
        },
      });
    },
    redirectToHome() {
      Segment.trackUserGot('MemberInviteExistingCustomerPage_ContinueToCashflowClicked');
      this.$router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-image {
  width: 100%;
}
</style>
