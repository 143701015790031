<template>
  <popup>
    <template v-slot:content>
      <div class="churn-through-member-popup">
        <lottie-player class="check-animation" src="/animations/Check.json" :autoplay="true"/>
        <div class="ri-medium-bold-headline margin-top-ml margin-bottom-sm">החשבון הקודם שלך נמחק</div>
        <div class="ri-body margin-bottom-ml">אפשר להמשיך בתהליך ההצטרפות לתזרים המשותף</div>
        <riseup-button
          title="המשך להצטרפות"
          variant="primary"
          size="slim"
          :action="redirectToInviteMember"
          />
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions } from 'vuex';

export default {
  name: 'ChurnThroughMemberInviteCompletePopup',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    inviteToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkColor: BaseUI.Colors.riseupGreen,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['closeAllModals']),
    redirectToInviteMember() {
      this.close();
      Segment.trackUserGot('ImmediateChurnCompletedPopup_ContinueClicked');
      this.$router.push({ path: `invite?inviteToken=${this.inviteToken}` });
    },
    close() {
      this.closeAllModals();
    },
  },
};
</script>

<style lang="scss" scoped>
.churn-through-member-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .check-animation {
    width: 85%;
  }
}
</style>
