<template>
  <div class="login-as">
    <text-input
      :autofocus="true"
      :labelText="'CustomerToken'"
      :value="customerToken"
      :onInput="onEnter"
      :error="!!serverErrors.loginAs"
      />
    <riseup-button :disabled="disabled" :title="'למעבר לתזרים הלקוח'" :action="triggerAction" :loading="loading"/>
  </div>
</template>
<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapMutations, mapState } from 'vuex';
import router from '../../../../router';

export default {
  name: 'LoginAs',
  components: {
    TextInput: BaseUI.TextInput,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      customerToken: '',
      redirectTo: '',
      loading: false,
    };
  },
  async created() {
    this.redirectTo = this.$route.query.redirectTo || 'sr';
    if (this.$route.query.customerToken) {
      this.customerToken = this.$route.query.customerToken;
      await this.triggerAction();
    }
  },
  computed: {
    ...mapState('login', ['serverErrors']),
    disabled() {
      return _.isEmpty(_.trim(this.customerToken));
    },
  },
  methods: {
    ...mapMutations('login', ['resetError']),
    ...mapActions('login', ['loginAs']),
    onEnter(value) {
      this.resetError();
      this.customerToken = value;
    },
    async triggerAction() {
      this.loading = true;
      await this.loginAs(this.customerToken);
      const tokenParam = this.getTokenParam();
      const carryParams = _.omit(this.$route.query, ['redirectTo', 'customerToken', 'carryToken']);
      router.push({ path: `/${this.redirectTo}`, query: _.extend(tokenParam, carryParams, { incognito: true }) });
      this.loading = false;
    },
    getTokenParam() {
      if (this.$route.query.carryToken === 'true') {
        if (this.$route.query.redirectTo === 'invite') {
          return { inviteToken: this.customerToken };
        }
        return { accessToken: this.customerToken };
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>

  .login-as {
    margin: 30px;

    .riseup-button {
      width: 100%;
      margin: 30px 0px;
    }
  }

</style>
